<template>
  <div>
    <pop-up-layout :title="'Create Job'">
      <div class="row">
        <!-- <div class="col-md-6">
          <v-text-field
            label="Job No."
            placeholder="Job No."
            v-model="form.job_no"
            v-bind="basic"
            outlined
          ></v-text-field>
        </div> -->
        <div class="col-md-6">
          <v-text-field
            label="Job Title*"
            placeholder="Job Title"
            v-model="form.job_title"
            v-bind="basic"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="form.start_date"
                clearable
                label="Start Date"
                readonly
                v-bind="{ ...basic, ...attrs }"
                v-on="on"
                @click:clear="form.start_date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.start_date"
              @change="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="col-md-6">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="form.due_date"
                clearable
                label="End Date"
                readonly
                v-bind="{ ...basic, ...attrs }"
                v-on="on"
                @click:clear="form.due_date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.due_date"
              @change="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="col-md-6">
          <v-text-field
            label="Progress"
            placeholder="10.00"
            v-model="form.progress"
            v-bind="basic"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-select
            :items="jobTypeList"
            item-text="title"
            item-value="id"
            v-bind="basic"
            label="Job Type"
            v-model="form.job_type_id"
            placeholder="Please Select Type"
            outlined
          ></v-select>
        </div>
        <div class="col-md-6">
          <v-select
            :items="jobStatusList"
            item-text="title"
            item-value="id"
            v-bind="basic"
            label="Job Status"
            v-model="form.job_status_id"
            placeholder="Please Select Job Status"
            outlined
          ></v-select>
        </div>
        <div class="col-md-6">
          <v-select
            :items="customerList"
            item-text="full_name"
            item-value="id"
            v-bind="basic"
            v-model="form.customer_id"
            label="Customer"
            placeholder="Please Select"
            outlined
          ></v-select>
        </div>
        <div class="col-md-6">
          <v-text-field
            label="Job Site"
            placeholder="Job Site"
            v-model="form.job_site"
            v-bind="basic"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-text-field
            label="Code"
            placeholder="Code"
            v-model="form.code"
            v-bind="basic"
            outlined
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <v-select
            :items="jobPriorityList"
            item-text="title"
            item-value="id"
            v-bind="basic"
            label="Priority"
            v-model="form.priority_id"
            placeholder="Please Select Priority"
            outlined
          ></v-select>
        </div>
        <div class="col-md-6">
          <v-textarea
            outlined
            name="input-7-4"
            v-bind="basic"
            v-model="form.job_description"
            label="Job Description"
            value=""
          ></v-textarea>
        </div>
        <div class="col-md-6">
          <p class="font-weight-bold">Is Internal?</p>
          <!-- <v-radio-group v-model="row" row> -->
            <input
              type="radio"
              label="Yes"
              v-model="form.is_internal"
              value="1"
            /> Yes <br>
            <input
              type="radio"
              label="No"
              v-model="form.is_internal"
              value="0"
            /> No
          <!-- </v-radio-group> -->
        </div>
        <div class="col-md-12">
          <hr style="border-top: 1px solid #ddd">
        </div>
        <div class="col-md-12">
          <v-btn
            type="button"
            v-on:click="save"
            :loading="loading"
            class="submit-btn mr-3"
          >
            Submit
          </v-btn>
          <router-link to="/jobs">
            <v-btn class="cancel-btn float-right">Cancel</v-btn>
          </router-link>
        </div>
      </div>
    </pop-up-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import PopUpLayout from "@/components/PopUpLayout.vue";
export default {
  components: {
    PopUpLayout,
  },
  data() {
    return {
      util,
      basic: util.input.basic,
      menu1: false,
      menu2: false,
      jobTypeList: [],
      jobStatusList: [],
      jobPriorityList: [],
      customerList: [],
      form: {
        job_number: "",
        job_title: "",
        items: ["Foo", "Bar", "Fizz", "Buzz"],
        start_date: null,
        due_date: null,
        progress: "",
        job_type: null,
        job_status_id: null,
        customer_id: "",
        job_site: "",
        code: "",
        priority: null,
        job_description: "",
        is_internal: 0,
      },
      row: null,
    };
  },
  mounted() {
    this.getJobTypeList();
    this.getJobStatusList();
    this.getJobPriorityList();
    this.getCustomerList();
  },

  methods: {
    save() {
      this.loading = true;
      util
        .http({
          url: "/job",
          method: "POST",
          data: { ...this.form },
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          if (resp.data.status == 1) {
            util.notify(1, "Job created Successfullly");
            this.$router.push({ name: "jobs.detail", params: { id: resp.data.data.id } });
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
      console.log(this.form);
    },
    getJobTypeList() {
      util.http({ url: "/job-type" }).then((res) => {
        this.jobTypeList = res.data.data.data;
      });
    },
    getJobStatusList() {
      util.http({ url: "/job-status" }).then((res) => {
        this.jobStatusList = res.data.data.data;
      });
    },
    getJobPriorityList() {
      util.http({ url: "/job-priority" }).then((res) => {
        this.jobPriorityList = res.data.data.data;
      });
    },
    getCustomerList() {
      util.http({ url: "/customer/get-all" }).then((res) => {
        this.customerList = res.data.data;
      });
    },
    cancel() {
      this.$router.push({ name: "jobs" });
    }
  },
};
</script>
